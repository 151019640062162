import React, { Fragment } from "react";
import { trackEvent } from "web/core/analytics";

class TrackOnMount extends React.Component {
    constructor() {
        super();
        this.track = this.track.bind(this);
    }

    track(prevProps) {
        if (
            this.props.isResolvedFromProps &&
            (!prevProps || this.props.shouldUpdateEvent(prevProps, this.props))
        ) {
            const track = this.props.trackEvent || trackEvent;
            track(this.props.event, this.props.properties);
        }
    }

    componentDidMount() {
        if (
            this.props.isResolvedFromProps &&
            this.props.shouldMountEvent(this.props)
        ) {
            this.track();
        }
    }

  /**
   * Sometimes we want to track an update like we stay on the same component
   * as we navigate between CmsPages it's the same component.
   * 
   * @param prevProps
   * @param prevState
   * @param snapshot
   */
    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.shouldFireOnComponentUpdate(prevProps, this.props)) {
        this.track();
      }
    }

  render() {
        return null;
    }
}

TrackOnMount.defaultProps = {
    shouldMountEvent: () => true,
    shouldUpdateEvent: () => true,
    shouldFireOnComponentUpdate: () => false,
    isResolvedFromProps: true,
};

const withTrackOnMount = ({
                              event,
                              isResolvedFromProps,
                              mapPropsToProperties,
                              shouldMountEvent,
                              shouldUpdateEvent,
  shouldFireOnComponentUpdate,
                              trackEvent,
                          }) => (BaseComponent) => {
    return (props) => {
        const isResolved = isResolvedFromProps ? isResolvedFromProps(props) : true;
        return (
            <Fragment>
                <TrackOnMount
                    {...props}
                    shouldMountEvent={shouldMountEvent}
                    shouldUpdateEvent={shouldUpdateEvent}
                    shouldFireOnComponentUpdate={shouldFireOnComponentUpdate}
                    event={event}
                    properties={isResolved ? mapPropsToProperties(props) : null}
                    isResolvedFromProps={isResolved}
                    trackEvent={trackEvent}
                />
                <BaseComponent {...props} />
            </Fragment>
        );
    };
};

export default withTrackOnMount;

export { TrackOnMount };
